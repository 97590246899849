import fetch from '@/utils/fetch'

//登录
export function login(params) {
    return fetch({
        url: '/web/login',
        method: 'post',
        data: params
    })
}

//微信扫一扫sdk
export function wxbase(url) {
    return fetch({
        url: '/other/Settings/getJsSdk',
        method: 'post',
        data: {
           url 
        }
    })
}

//用户信息
export function getUserInfo(params) {
    return fetch({
        url: 'web/getUserInfo',
        method: 'post',
        data: params
    })
}