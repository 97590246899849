import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken } from '@/utils/auth'
Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      redirect: '/Home'
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  	meta: {
  		requireBelong: 'Login'
  	}
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  	meta: {
  		requireBelong: 'Home'
  	}
  },
  {
    path: '/RepairList',
    component: () => import('../views/repairManage/RepairList.vue'),
    meta: {
      requireBelong: 'Home'
    }
  },
  {
    path: '/RepairDetail',
    component: () => import('../views/repairManage/RepairDetail.vue'),
    meta: {
      requireBelong: 'Home'
    }
  },
  {
    path: '/DeviceTestList',
    component: () => import('../views/deviceTest/DeviceTestList.vue'),
    meta: {
      requireBelong: 'Home'
    }
  },
  {
    path: '/DeviceTest',
    component: () => import('../views/deviceTest/DeviceTest.vue'),
    meta: {
      requireBelong: 'Home'
    }
  },
  {
    path: '/User',
    component: () => import('../views/user/Index.vue'),
    meta: {
      requireBelong: 'User'
    }
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  if(getToken()){
      if(!store.state.userInfo){
          store.dispatch('GenerateUserInfo').then((res) => {
            next()  
          })
      }else{
          next()
      }
  }else{
    if(to.fullPath != '/Login'){
      next('/Login')
    }else{
      next()
    }
    
  }

})
export default router
