import axios from 'axios'
import qs from 'qs'
import { getToken } from '@/utils/auth'

const service = axios.create({
	baseURL: process.env.VUE_APP_ROOT,
    timeout: 150000,// 请求超时时间
    withCredentials: false
})

service.interceptors.request.use(config => {
	if (getToken()) {
		config.headers['token'] = getToken()
    }
    if(config.method == "get"){
        config.url = config.url + '&' + qs.stringify(config.data);
    }   
	return config
}, error => {
    return Promise.reject(error)
})

service.interceptors.response.use(// respone拦截器
    response => {
        console.log(response)
        return response
    },
    error => {
        
        console.log(error)
    }
)

export default service
