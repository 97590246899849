import Vue from 'vue'
import Vuex from 'vuex'
import { login , getUserInfo } from '@/api/user'
import { setToken } from '@/utils/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	usersInfo:{}
  },
  mutations: {
	setToken: (state, token) => {
		state.token = token
	},
	setUsersInfo: (state, userInfo) => {
		state.userInfo = userInfo
	},
  },
  actions: {
	login({ commit }, userInfo) {
		userInfo.username = userInfo.username.trim()
		return new Promise((resolve, reject) => {
			login(userInfo).then(response => {
				console.log('login api success')
				const data = response.data              
				setToken(data.data.token)
				commit('setToken', data.data.token)
				resolve(data)
			}).catch(error => {
				console.log('login api error')
				reject(error)
			})
		})
	},
	GenerateUserInfo({commit},state) {
		return new Promise((resolve, reject) => {
			getUserInfo().then(response => {
				commit('setUsersInfo', response.data.data)
				resolve(response.data)
			}).catch(error => {
				console.log('拉取权限失败')
				reject(error)
			})
		})

    },
  },
  modules: {

  }
})
