import cookies from 'js-cookie'
const tokenKey = 'v-merp-token'

export function getToken() {
    return cookies.get(tokenKey)
}

export function setToken(token) {
    return cookies.set(tokenKey, token)
}

export function removeToken() {
    return cookies.remove(tokenKey)
}
